@import '../../assets/style/theme';

#search {
  padding: 0 15px;

  .link-text {
    font-size: 14px;
  }

  .auth {
    width: 120px;
   span{
     display: flex;
     justify-content: flex-end;
     align-items: center;
   }

    .anticon {
      font-size: 28px;
      margin: 5px 10px;
      cursor: pointer;
    }
  }

  .ant-input{
    font-size: 16px;
  }

  .see-more {
    text-align: center !important;
    font-size: 14px;
    color: $primary;
  }

  .logo {
    height: 45px;
    margin: 0;
    margin-left: 25px;
    cursor: pointer;
  }

  .search-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
  }

  .search-box {
    position: relative;
  }

  .search-icon{
    width: 24px;
    font-size: 20px;
    cursor: pointer;
    color: $gray;
  }

  .ant-input-clear-icon{
    display: none;
  }


  .ant-input-affix-wrapper {
    height: 45px;
    padding: 5px 15px !important;
    font-size: 18px;
    margin: 0 15px;
    background: #f5f5f5;
    border: 1px solid #d9d9d9;
    font-family: IRANSans;
    border-radius: 30px !important;
    @media (max-width: 576px) {
      width: calc(100vw - 70px);
      margin: 0 0;
    }
  }
  .hideBackButton .ant-input-affix-wrapper{
    @media (max-width: 576px) {
      width: calc(100vw - 34px);
    }
  }

  .ant-btn-icon-only {
    position: absolute;
    left: -7px;
    background: transparent;
    top: 8px;
    z-index: 2;
    display: block !important;
    border: none;
    @media (max-width: 576px) {
      left: 10px;
    }
  }

  .ant-menu-overflow-item-rest{
    position: absolute !important;
  }
  .ant-menu-item-group{
  text-align: right;
  }


  .ant-menu::before{
    display: none;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: transparent;
    direction: rtl;
  }
  .ant-input-group{
    right: -15px
  }

  .ant-input-group-wrapper {
    width: 100%;
    margin: 0 15px;
  }


  .ant-btn > .anticon {
    font-size: 22px;
  }

  .ant-layout-header {
    box-shadow: 0 2px 8px #f0f1f2;
  }

  .ant-menu-horizontal {
    display: flex;
    justify-content: space-around;
    height: 70px;
    margin: 0 4%;
    &:after{
      display: none;
    }
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: rgba(0, 0, 0, 0.85);
    border-bottom: none;
  }

  @media (max-width: 576px) {
    padding: 0;
    .logo{
      display: none;
    }
    .ant-input-group{
      right: 0
    }
    .back-icon{
      font-size: 20px;
      margin: 10px;
    }
    .parent-container{
      margin: 0 15px;
      height: 55px;
      display: flex;
      border-bottom: 1px solid #eee;
      justify-content: space-between;
      h3{
        font-weight: bold;
        font-size: 15px;
      }
      span{
        font-size: 12px;
      }
    }
    .navbar-mobile{
      position: fixed;
      bottom: 20px;
      z-index: 12;
      width: 70%;
      height: 60px;
      background: white;
      box-shadow: 0 0 20px #ddd;
      border-radius: 50px;
      left: 15%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #aaa;
      font-size: 32px;
      img{
        height: 24px;
      }
    }

  }

}

.ant-menu-submenu {
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: $primary;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ff52520d;
    color: $primary;
  }

  .ant-menu-item-group-title {
    color: #1a1a1a;
    cursor: pointer;

    &:hover {
      color: $primary !important;
    }
  }

  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item, .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    height: 25px;
    line-height: 25px;
    margin: 0;

    &:hover {
      color: $primary;
    }
  }

  .ant-menu-item-group-list {
    position: absolute;
    background: white;
    left: -160px;
    top: 0;
    min-height: 200px;
    box-shadow: -5px 5px 15px rgba(157, 156, 156, 0.25);
    padding: 0px 0 !important;
    display: none;
    width: 160px;
    border-radius: 15px;
  }

  .ant-menu-vertical.ant-menu-sub {
    min-height: 200px;
    border-radius: 15px;
  }

  .have_sub_category:hover {
    > .ant-menu-item-group-list {
      display: block;
    }
  }

  .ant-menu-item-group-title {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
  }

  .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item, .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 15px;
  }


  .anticon-caret-left {
    font-size: 12px;
    margin-top: 5px;
  }


}

.no-children{
  display: none !important;
}

.anticon-left{
  margin-top: 5px;
  font-size: 12px;
}
.ant-dropdown-menu{
  border-radius: 15px;
  min-width: 140px !important;
}

@media (max-width: 576px) {
  .ant-input-group-wrapper{
    margin: 0 7px !important;
  }
  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after{
    display: none;
  }
  .search-container{
    align-items: end !important;

  }
  .ant-layout-header{
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
  }
  .auth{
    display: none !important;
  }
  .ant-menu-title-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-menu-item-selected{
    color: #1a1a1a;
    background: white !important;

    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
      border-right: none;
    }
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: $primary;
  }

}
