#HomeMobile {
  .home-section {
    padding: 3.5% 7%;
    @media screen and  (max-width: 1000px){
      padding: 10px 10px 20px 10px;
    }
  }

  .home-title {
    text-align: right;
    font-size: 18px;
  }

  .see-more{
    cursor: pointer;
    float: left;
    color: blue;
    @media screen and  (max-width: 1000px){
      margin: 15px 0;
    }
  }

  @media screen and  (max-width: 1000px) {
    .ant-tabs {
      padding: 0;
    }
  }

}
