@import '../../assets/style/theme';

.ant-tabs-tab {
  font-family: IRANSans;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary;
}

.ant-tabs-tab:hover {
  color: #ff5252;
}

.ant-tabs-ink-bar {
  display: none;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before{
  border: 1px solid #d9d9d9;
}

