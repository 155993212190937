@import '../../assets/style/theme';


#footer{
  min-height: 100px;
  background-color: $primary;
  padding: 50px;
  padding-bottom: 100px;
  color: white;
  .logos{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media screen and (max-width: 576px) {
      flex-flow: column;
      h1{
        font-size: 32px;
      }
      img{
        margin: 15px 0 !important;
      }
    }

  }
  h1{
    font-size: 38px;
    font-weight: bold;
    color: white;
  }
  p{
    font-size: 18px;
    margin: 15px auto;
    text-align: center;
    max-width: 425px;
  }
  Button{
    justify-content: space-between;
    align-items: center;
    background: $primary;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 30px;
    .anticon-android{
      font-size: 24px;
    }
    span{
      color: white;
      font-size: 20px;
    }
    img{
      width: 50px;
      margin-right: 15px;
    }
  }
  .social{
    font-size: 36px;
    > div {
      a {
        display: flex;
        align-items: center;
        color: white;
        label{
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .anticon{
      margin: 20px 10px;
      cursor: pointer;
    }

  }
}


.contactus-popup{
  display: flex;
  flex-flow: column;
  text-align: center;
}

.install-popup{
  display: flex;
  flex-flow: column;
  text-align: center;
.logo{
  width: 80px;
  margin: auto;
}
h2{
  font-size: 16px;
  margin-top: 30px;
}
  >div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p{
      margin: 10px 0;
      text-align: right;
      direction: rtl;
    }
  }
  >div:last-child{
    justify-content: center;
  }
}



.show{
  display: block!important;
}
.hide{
  display: none!important;
}