@import '../../assets/style/theme';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url("../fonts/Roboto-Regular.ttf") format('truetype');
}

/*This set of fonts are used in this project under the license: (HQXUY)*/
@font-face {
  font-family: 'IRANSans';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/iransans/eot/IRANSansWeb_Bold.eot');
  src: url('../fonts/iransans/eot/IRANSansWeb_Bold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../fonts/iransans/woff2/IRANSansWeb_Bold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/iransans/woff/IRANSansWeb_Bold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/iransans/ttf/IRANSansWeb_Bold.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/iransans/eot/IRANSansWeb_Medium.eot');
  src: url('../fonts/iransans/eot/IRANSansWeb_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../fonts/iransans/woff2/IRANSansWeb_Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/iransans/woff/IRANSansWeb_Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/iransans/ttf/IRANSansWeb_Medium.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/iransans/eot/IRANSansWeb_Light.eot');
  src: url('../fonts/iransans/eot/IRANSansWeb_Light.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../fonts/iransans/woff2/IRANSansWeb_Light.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/iransans/woff/IRANSansWeb_Light.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/iransans/ttf/IRANSansWeb_Light.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/iransans/eot/IRANSansWeb_UltraLight.eot');
  src: url('../fonts/iransans/eot/IRANSansWeb_UltraLight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../fonts/iransans/woff2/IRANSansWeb_UltraLight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/iransans/woff/IRANSansWeb_UltraLight.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/iransans/ttf/IRANSansWeb_UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/iransans/eot/IRANSansWeb.eot');
  src: url('../fonts/iransans/eot/IRANSansWeb.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('../fonts/iransans/woff2/IRANSansWeb.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('../fonts/iransans/woff/IRANSansWeb.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('../fonts/iransans/ttf/IRANSansWeb.ttf') format('truetype');
}

@font-face {
  font-family: IRANSansNum;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/iransans/FaNum/ttf/IRANSansWeb(FaNum).ttf');

}
html{
  scroll-behavior: smooth;
}
body{
  width: 100% !important;
  overflow-y: visible !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: IRANSansNum, IRANSans, sans-serif;
}

a {
  color: $primary;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.mx-10 {
  margin: 0 10px;
}

.mx-15 {
  margin: 0 15px;
}

.my-10 {
  margin: 10px 0;
}

.my-15 {
  margin: 15px 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-xs{
  @media screen and (max-width: 576px) {
    flex-flow: column;
  }
}

.ant-btn-primary, .btn-primary{
  background: $primary;
  border-color: $primary;
  padding: 8px 16px;
  height: auto;
  font-size: 16px;
  min-width: 80px;
  color: white !important;
  border-radius: 30px;
  transition: opacity 0.3s;
}
.ant-btn-primary:hover, .ant-btn-primary:focus, .btn-primary:hover, .btn-primary:focus, {
  background: $primary;
  border-color: $primary;
  opacity: 0.8;
}

.link-text {
  cursor: pointer;
  font-size: 12px;
  color: #9aa0a6;
}



.link-text2 {
  cursor: pointer;
  font-size: 12px;
  color: $primary;
}

.box {
  background-color: white;
  margin: 15px;
  padding: 15px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 20px #ddd;
}

.relative {
  position: relative;
}

.category-item {
  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .image {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 2px 10px #bbb;
    }
    @media screen and (max-width: 480px) {
        width: 135px;
        height: 135px;
    }
  }

  h4 {
    text-align: center;
    margin-top: 10px;
  }
}

