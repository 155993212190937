@import '../../assets/style/theme';

.full-page-loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #f2f2f2a3;
  z-index: 20;
}

.spinner-container {
  position: relative;
  display: flex;
  top: 200px;
  justify-content: center;
  height: 100%;
}
