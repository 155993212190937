#Head {
.home-section{
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 15px 10px;
}
.head-logo{
  height: 50px;
}
.home-title{
  margin-top: 0;
  text-align: center;
  font-size: 26px;
}
  p{
    font-size: 15px;
    text-align: center;
  }
  .btn-primary{
    width: 100px;
    margin: 0 auto;
  }
  .not_auth{
    min-height: 300px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    h4{
      text-align: center;
      margin-top: 15px;
      font-weight: bold;
      font-size: 15px;
    }
  }


}
