@import '../../assets/style/theme';

.ant-layout {
  background: white;
}

.ant-layout-header {
  background: #fff;
  padding: 15px 2.5% 0 2.5%;
  height: auto;
}

.ant-layout-content {
  padding: 0;
  background: #fff;
  @media (max-width: 576px) {
    padding: 75px 0 0 0;
  }
}

.ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
}

.ant-modal-header {
  border-bottom: 0;
  text-align: right;
  background-color: white;
}

.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
  border-bottom: none !important;
}

.ant-menu-vertical .ant-menu-item{
  text-align: right;
}

.ant-modal-close {
  left: -25px !important;
  top: -25px !important;
  @media screen and  (max-width: 576px){
    left: 0 !important;
    top: 0 !important;
  }
  .anticon-close{
    font-size: 18px;
    top: 16px;
    left: 16px;
    position: absolute;
  }
}

.ant-modal-close-x {
  width: 50px;
  height: 50px;
  background-color: white;
  box-shadow: 0 0 15px rgba(79, 78, 78, 0.1);
  border-radius: 100%;
  @media screen and  (max-width: 576px){
     box-shadow: none;
  }
}

.ant-modal-content, .ant-modal-header {
  border-radius: 30px;
}
.ant-input{
  padding: 8px 12px;
  border-radius: 40px;
  background: #f5f5f5;
  direction: ltr;
}
.ant-input-affix-wrapper{
  padding: 8px 12px;
  border-radius: 40px;
  background: #f5f5f5;
}

.ant-modal-content .ant-btn-primary{
  width: 100%;
}
.ant-alert-message{
  font-size: 12px;
}
.ant-input-password-icon{
  width: 20px;
  margin-right: 5px;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title{
  height: 30px;
}

.ant-menu-submenu .ant-menu-item-group-list{
  padding: 0 !important;
}

.ant-pagination-item, .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  border-radius: 30px;
}

.image-gallery-bullets .image-gallery-bullet:hover{
  background: $primary !important;
  border: 1px solid $primary !important;
}
