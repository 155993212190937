#home {
  .home-section {
    padding: 3.5% 2.5%;
    @media screen and  (max-width: 1000px){
      padding: 40px 10px 60px 10px;
    }
  }
  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
    padding: 0 7%;
  }
  .home-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .see-more{
    cursor: pointer;
    float: left;
    @media screen and  (max-width: 1000px){
      margin: 15px 0;
    }
  }

  @media screen and  (max-width: 1000px) {
    .ant-tabs {
      padding: 0;
    }
  }

}
