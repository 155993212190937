@import '../../assets/style/theme';

.image-item {
  position: relative;
  display: flex;
  // background-color: rgb(220, 253, 249);
  background-color: rgb(245, 245, 245);
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 0;
  margin: 15px;
  height: 230px;
  cursor: pointer;
  overflow: hidden;
  @media screen and (max-width: 1800px) {
    height: 210px;
  }
  @media screen and (max-width: 1500px) {
    height: 180px;
  }
  @media screen and (max-width: 1300px) {
    height: 180px;
  }

  .image-gallery-icon {
    position: absolute !important;
    left: 10px;
    top: 10px;
    opacity: 0.8;
    width: 24px;
  }

  .collection-img {
    height: 300px;
  }

  .like{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background: white;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    opacity: 0;
    transition: opacity 0.5s;
    .like-icon{
      width: 24px;
      margin:9px 8px;
    }
    .anticon-spin{
       font-size: 20px;
    }
    @media (max-width: 576px) {
      width: 36px;
      height: 36px;
      .like-icon{
       width: 24px;
      }
    }
  }
  .image {
    width: 100%;
    height: auto;
  }
  &:hover {
    box-shadow: 0 2px 10px #bbb;
    .like{
      opacity: 1;
    }
  }
  @media screen and (max-width: 576px) {
    height: 130px;
    margin: 5px;
  }


}


