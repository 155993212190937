@import '../../assets/style/theme';

#SearchList{
  padding: 2.5%;
  .no-data {
    height: 300px;
  }
  .image-container{
    position: relative;
    .anticon{

      cursor: pointer;
      color: gray;
      font-size: 24px;
      opacity: 0;
      transition: opacity 0.5s;
    }
    .anticon-ellipsis{
      position: absolute;
      bottom: 0;
      right: 0;
    }
    &:hover{
      .anticon{
        opacity: 1;
      }
    }
  }
  .pagination {
    margin: 25px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 576px) {
    padding: 10px;
    .image-container .anticon {
      color: black;
      background: white;
      border-radius: 20px;
      padding: 5px;
      box-shadow: 0 2px 15px #afadad;
      right: 15px;
      bottom: 10px;
    }
  }

  .ant-pagination-item-active {
    border-color: $primary !important;
    color: $primary;
  }

  .ant-pagination-item-active a {
    color: $primary !important;
  }

  .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
    color: $primary;
  }

  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: $primary !important;
    color: $primary !important;

    a {
      color: $primary !important;
    }
  }
}

