#Favorites {

  .slick-list{
    height: 250px;
    @media screen and  (max-width: 1600px){
      height: 230px;
    }
    @media screen and  (max-width: 1400px){
      height: 210px;
    }
  }






}
