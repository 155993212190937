#MadeOf {



  .image-item{
    height: 200px;
  }

  @media screen and (max-width: 1500px) {
    .image-item{
      height: 180px;
    }
  }





}
